/**
 * Définition du mapping pour la ressource 'ETABLISSEMENT'. L'objectif est de fournir un mapping
 * entre les champs retournés par la ressource et ceux utilisés dans l'application.
 * Le mapping fournira la correspondance:
 *  - nom du libllé à afficher : ex 'Responsable de l'établissement'
 *  - nom du champs de la ressource : ex 'responsableDeLEtablissement *
 *  - la position dans le formulaire d'édition *
 *  - est un champs de type 'raw' (json)
 */
export default class PropertiesMapping {
  constructor() {}

  /**Retourne le mapping des champs */
  getMapping() {
    return this.buildMapping();
  }

  /** Retourne un champ pour la saisie du link de contrat de travail */
  getLinkContratNumber() {
    return {
      label: "Lien vers le numéro de contrat",
      property: "lienVersNumeroContrat",
      order: -1,
      hint: null,
      isRaw: true,
    };
  }

  /**Construire le mapping des champs */
  buildMapping() {
    var mapping = [];

    let order = 1;

    //A --> index 0
    mapping.push({
      label: "Numéro",
      property: "numero",
      order: order++,
      hint: null,
      isRaw: false,
    });

    //B
    mapping.push({
      label: "ETABLISSEMENT",
      property: "nomDigital",
      order: order++,
      hint: null,
      isRaw: false,
    });

    //C
    mapping.push({
      label: "METIER",
      property: "metier",
      order: order++,
      hint: null,
      isRaw: false,
    });

    //D
    mapping.push({
      label: "Activité 1 = Activité Principale",
      property: "activite_1",
      order: order++,
      hint: null,
      isRaw: false,
    });

    //E
    mapping.push({
      label: "Activité 2",
      property: "activite_2",
      order: order++,
      hint: null,
      isRaw: false,
    });

    //F --> index 5
    mapping.push({
      label: "Activité 3",
      property: "activite_3",
      order: order++,
      hint: null,
      isRaw: false,
    });

    //G
    mapping.push({
      label: "Exploité, Fermé, Sorti",
      property: "exploiteFermeSorti",
      order: order++,
      hint: null,
      isRaw: false,
    });

    //H
    mapping.push({
      label: "E-mail du dop",
      property: "emailDuDop",
      order: order++,
      hint: null,
      isRaw: true,
    });

    //I
    mapping.push({
      label: "Nom du responsable de l'établissement",
      property: "nomDuResponsableDeSite",
      order: order++,
      hint: null,
      isRaw: true,
    });

    //J
    mapping.push({
      label: "Email du responsable de site",
      property: "emailDuResponsableDeSite",
      order: order++,
      hint: null,
      isRaw: true,
    });

    //K --> index 10
    mapping.push({
      label: "Téléphone du responsable de site",
      property: "telephoneDuResponsableDeSite",
      order: order++,
      hint: null,
      isRaw: true,
    });

    //L
    mapping.push({
      label: "Nom établissement",
      property: "nomEtablissement",
      order: order++,
      hint: null,
      isRaw: false,
    });

    //M
    mapping.push({
      label: "Adresse email établissement",
      property: "adresseEmailEtablissement",
      order: order++,
      hint: null,
      isRaw: false,
    });

    //N
    mapping.push({
      label: "Adresse établissement",
      property: "adresseEtablissement",
      order: order++,
      hint: null,
      isRaw: false,
    });

    //O
    mapping.push({
      label: "Ville établissement",
      property: "villeEtablissement",
      order: order++,
      hint: null,
      isRaw: false,
    });

    //P --> index 15
    mapping.push({
      label: "Code Postal établissement",
      property: "cpEtablissement",
      order: order++,
      hint: null,
      isRaw: false,
    });
    //Q
    mapping.push({
      label: "Département établissement",
      property: "departementEtablissement",
      order: order++,
      hint: null,
      isRaw: false,
    });
    //R
    mapping.push({
      label: "Nom de l'enseigne",
      property: "nomEnseigneEtablissement",
      order: order++,
      hint: null,
      isRaw: false,
    });
    //S
    mapping.push({
      label: "Tél fixe",
      property: "telephoneFixeEtablissement",
      order: order++,
      hint: null,
      // isRaw: true,
      isRaw: false,
    });
    //T
    mapping.push({
      label: "Téléphone Etablissement et/ou standard",
      property: "telephoneStandardEtablissement",
      order: order++,
      hint: null,
      // isRaw: true,
      isRaw: false,
    });
    //U --> index 20
    mapping.push({
      label: "Tél astreinte",
      property: "telephoneAstreinteEtablissement",
      order: order++,
      hint: null,
      // isRaw: true,
      isRaw: false,
    });
    //V
    mapping.push({
      label: "Fax établissement",
      property: "faxEtablissement",
      order: order++,
      hint: null,
      // isRaw: true,
      isRaw: false,
    });
    //W
    mapping.push({
      label: "Email adjoint responsable de site",
      property: "emailResponsableAdjointEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //X
    mapping.push({
      label: "Etablissement support Référent",
      property: "etablissementSupportReferenceEtablissement",
      order: order++,
      hint: null,
      isRaw: false,
    });
    //Y
    mapping.push({
      label: "Gare la plus proche",
      property: "garePlusProcheEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //Z --> index 25
    mapping.push({
      label: "Aéroport le plus proche",
      property: "aeroprtPlusProcheEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AA
    mapping.push({
      label: "Géolocalisation Google Maps",
      property: "geolocGMapsEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AB
    mapping.push({
      label: "Médecin coordonnateur (à renseigner pour les EHPAD)",
      property: "medecinCoordonnateurEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AC
    mapping.push({
      label: "Assureur Actuel",
      property: "assureurActuelEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AD
    mapping.push({
      label: "Numéro de contrat",
      property: "numeroContratAssuranceEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AE --> index 30
    mapping.push({
      label: "Lien vers attestation d'assurance (dg drive)",
      property: "lienAttestationAssuranceEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AF
    mapping.push({
      label: "Date de fin de contrat assurance",
      property: "dateFinContratAssuranceEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AG
    mapping.push({
      label: "Garanties souscrites",
      property: "garantiesSouscriteEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AH
    mapping.push({
      label: "Garanties à souscrire",
      property: "garantiesASouscrireEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AI
    mapping.push({
      label: "Fréquence de paiement assurance",
      property: "frequencePaiementAssuranceEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AJ --> index 35
    mapping.push({
      label: "Montant de prime par paiement",
      property: "montantPrimeParPaiementEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AK
    mapping.push({
      label: "Président de la CME (HOSPIT)",
      property: "presidentCMEEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AL
    mapping.push({
      label: "Règlement intérieur du CVS",
      property: "reglementInterieurCVSEtablissement",
      order: order++,
      hint: "lien vers le document dg drive",
      isRaw: true,
    });
    //AM
    mapping.push({
      label: "DUERP - Date de la dernière révision",
      property: "DUERPDateDerniereRevisionEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AN
    mapping.push({
      label: "DUERP Obligatoire",
      property: "DUERPObligatoireEtablissement",
      order: order++,
      hint: "lien vers le document dg drive",
      isRaw: true,
    });
    //AO --> index 40
    mapping.push({
      label: "Lien vers Document Unique Délégataire (dg drive)",
      property: "lienDUDEtablissement",
      order: order++,
      hint: "lien vers le document dans le dg drive",
      isRaw: true,
    });
    //AP
    mapping.push({
      label: "Plan Bleu (Oui/Non)",
      property: "planBleuEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AQ
    mapping.push({
      label: "Plan cannicule - Capacité d'accueil en sus des résidents",
      property: " planCanniculeCapaciteAcceuilEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AR
    mapping.push({
      label: "Plan cannicule - climatisation (Oui/Non)",
      property: "planCanniculeClimatisationEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AS
    mapping.push({
      label: "Plan cannicule - lieu de la climatisation",
      property: "planCanniculeLieuClimatisationEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AT --> index 45
    mapping.push({
      label: "Plan cannicule - superficie de la pièce",
      property: "planCanniculeSuperficiePieceEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AU
    mapping.push({
      label: "Commune d'intervention   Villes ( Code postal )",
      property: "communeInterventionEtablissement",
      order: order++,
      hint: "ex : Grasse ( 06130 ; 06740 ; 06530 )",
      isRaw: true,
    });
    //AV
    mapping.push({
      label: "Date du dernier projets de santé/soin",
      property: "dateDernierProjetSanteEtablissement",
      order: order++,
      hint: "ex : lien vers le document dg drive",
      isRaw: true,
    });
    //AW
    mapping.push({
      label: "Règlement Intérieur (lien dg drive)",
      property: "reglementInterieurEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AX
    mapping.push({
      label: "Date règlement de fonctionnement de l'établissement",
      property: "dateReglementFonctionnementEtablissement",
      order: order++,
      hint: "avec lien sur le document dg drive",
      isRaw: true,
    });
    //AY--> index 50
    mapping.push({
      label: "Adresse mail pour envoyer alerte reglementaire direction",
      property: "mailEnvoiAlerteReglementaireDirectionEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //AZ
    mapping.push({
      label: "Adresse mail pour envoyer alerte reglementaire dop",
      property: "mailEnvoiAlerteReglementaireDopEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BA
    mapping.push({
      label: "Adresse mail pour envoyer alerte reglementaire finance",
      property: "mailEnvoiAlerteReglementaireFinanceEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BB
    mapping.push({
      label: "Adresse mail pour envoyer alerte reglementaire qualite",
      property: "mailEnvoiAlerteReglementaireQualiteEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BC
    mapping.push({
      label: "Spécialités de l'activité ",
      property: "specialiteActiviteEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BD --> index 55
    mapping.push({
      label: "Soumis à tarification par le CD (Oui/Non) (HELP)",
      property: "soumisTarificationCdEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BE
    mapping.push({
      label: "Conventionné ou habilité à l'aide sociale",
      property: "conventionneOuHabiliteAideSocialeEtablissement",
      order: order++,
      hint: "indiquer le pourcentage entre 0% et 100% (HELP)",
      isRaw: true,
    });
    //BF
    mapping.push({
      label: "N° Agrément/Déclaration",
      property: "numeroAgrementEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BG
    mapping.push({
      label: "Date agrément",
      property: "dateAgrementTEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BH
    mapping.push({
      label: "Date dernière autorisation",
      property: "dateDerniereAutorisationEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BI --> index 60
    mapping.push({
      label: "Date prochaine évaluation externe",
      property: "dateProchaineEvaluationExterneEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BJ
    mapping.push({
      label: "Date prochaine évaluation interne",
      property: "dateEvaluationInterneEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BK
    mapping.push({
      label: "Date prochaine révision projet établissement",
      property: "dateProchaineRevisionProjetEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BL
    mapping.push({
      label: "Date du dernier rapport d'activité (lien dans le dg drive)",
      property: "dateDernierRapportDActiviteEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BM
    mapping.push({
      label: "Nombre de secteurs domicile",
      property: "nombreSecteurDomicileEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BN --> index 65
    mapping.push({
      label: "Projet associatif et/ou entreprenarial",
      property: "projetAssociatifEntreprenarialEtablissement",
      order: order++,
      hint: "Si oui lien vers le document dg drive",
      isRaw: true,
    });
    //BO
    mapping.push({
      label: "Saisie des éléments sur Nova",
      property: "saisieElementNovaEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BP
    mapping.push({
      label: "Télé-assistance \n (Oui/Non)",
      property: "teleAssistanceEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BQ
    mapping.push({
      label: "Comités d'amélioration : CLIN, CQV, CLAN",
      property: "comiteAmeliorationEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BR
    mapping.push({
      label: "Date de vérification des trousses de sécurité",
      property: "dateVerificationTroussesSecuriteEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BS --> index 70
    mapping.push({
      label: "Date de visite des services vétérinaires",
      property: "dateVisiteServicesVeterinairesEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BT
    mapping.push({
      label: "Nature Autorisation",
      property: "natureAuthorisationEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BU
    mapping.push({
      label: "Note certification (HOSPIT)",
      property: "noteCertificationEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BV
    mapping.push({
      label: "Date dernière visite de certification",
      property: "dateDerniereVisiteCertificationEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BW
    mapping.push({
      label: "Autorité de tutelle",
      property: "autoriteTutelleEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BX --> index 75
    mapping.push({
      label: "Organisme de certification",
      property: "organismeCertificationEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BY
    mapping.push({
      label: "Référentiel",
      property: "referentielEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //BZ
    mapping.push({
      label: "Zone d'intervention (SAAD, SSIAD)",
      property: "zoneInterventionEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //CA
    mapping.push({
      label: "Date Tableau de Bord ANAP ",
      property: "dateTableaudebordANAPEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //CB
    mapping.push({
      label: "Prestations déclarées auprès de la Direccte",
      property: "prestationDeclareeAupresDireccteEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //CC --> index 80
    mapping.push({
      label: "Prestations agréées auprès de la Direccte",
      property: "prestationAgreesAupresDireccteEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //CD
    mapping.push({
      label: "Prestations autorisées par le Conseil Départemental",
      property: "prestationAutoriseeParConseilDepartementalEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });
    //CE
    mapping.push({
      label: "Certification de service (OPTIQUE)",
      property: "certificationServiceEtablissement",
      order: order++,
      hint: null,
      isRaw: true,
    });

    return mapping;
  }
}
