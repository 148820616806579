import * as statusMapper from "@/service/error_request_mapper.js";
import * as stringTool from "@/tools/string_tool.js"

export class JobOfferRecipientService {
    constructor(api) {
        this.api = api;
    }

  async getAll(establishmentId) {
    return this.api
      .getJobOfferRecipientsByEstablishment(establishmentId)
      .then((data) => {
        stringTool.sortByProperty(data, "label");
        return data;
      })
      .catch((error) => {
        const converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  async updateAll(establishmentId, entities) {
    return this.api.updateEntities(establishmentId, entities)
    .catch((error) => {
      const converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

}