
import GeoApiGouv from "@/api/geo_api_gouv.js";


export default class GeoApiGouvService {
  constructor() {

    this.api = new GeoApiGouv();
  }


  /** Service de demande d'obtention de géocodage avec adresse et code postal */
  async searchByAddressAndPostalCode(address, postalCode) {

    let geocode = null;
    let response = await this.api.searchByAddressAndPostalCode(address, postalCode);

    if(response) {
      
      if (response.data.features && response.data.features.length > 0) {
        // Récupération de l'ensemble des scores
        let scores = response.data.features.map((m) => m.properties.score);
        // Obtention du score le plus élevé
        let scoreMax = Math.max(...scores);
        // Recherche de la réponse qui as le plus haut score
        let result = response.data.features.find((r) => r.properties.score == scoreMax);
        
        // Fomattage de la donnée orienté besoin
        geocode = {};
        geocode.score = result.properties.score;
        geocode.latitude = result.geometry.coordinates[1];
        geocode.longitude = result.geometry.coordinates[0];
      }
    }
    return geocode;
  }


}