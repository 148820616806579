<template>
  <v-dialog v-model="show" width="1000px">
    <v-card>
      <v-card-title>
        <span class="headline">
          Situer l'établissement sur la carte
        </span>
      </v-card-title>

      <!-- L'adresse et btn de géoloc -->
      <v-card-text>
        <v-row no-gutters align="center">
          <div class="capitalize font-weight-regular mx-4 py-2"
          align="center">
            adresse :    
          </div>

          <div class="capitalize font-weight-regular py-2"
          align="center">
            {{ fullAddress }}
          </div>          

          <!-- Bouton pour géoloc l'adresse -->
          <v-spacer></v-spacer>
          <v-btn 
          v-if="!onlyReadMode"
          outlined
          class="ma-2 px-4 btn"
          align-self="center"
          color="primary"
          @click="geolocAddress"
          :loading="loadingGeocodage"
          >
            <div class="capitalize">
              Géocoder l'adresse
            </div>
          </v-btn>
        </v-row>

        <!-- Indication de géoloc -->
        <v-row no-gutters align="center">
          <div class="capitalize font-weight-regular mx-4 py-2"
          align="center">
            latitude :    
          </div>

          <div class="capitalize font-weight-bold py-2"
          align="center"
          :style="'color: #'+colorText">
            {{ lat }}
          </div> 
          

          <div class="capitalize font-weight-regular ml-12 mr-4 py-2"
          align="center">
            longitude :    
          </div>

          <div 
          class="capitalize font-weight-bold py-2"
          align="center"
          color="secondary"
          :style="'color: #'+colorText">
            {{ lng }}
          </div> 


          <div 
          class="capitalize font-weight-regular ml-12 mr-4 py-2"
          align="center" 
          v-if="!onlyReadMode">
            Fiabilité du géocodage (note de 0 à 1) :    
          </div>

          <div class="capitalize font-weight-regular py-2"
          align="center" v-if="!onlyReadMode">
            {{ score }}
          </div>          
        </v-row>
      </v-card-text>

      <!-- Mention pour le déplacement du marker -->
      <v-row no-gutters align="center" v-if="!onlyReadMode">
        <div class="capitalize font-weight-medium mx-6 my-2"
        align="center">
          Déplacer le repère pour améliorer la précision du géocodage.    
        </div>
      </v-row>

      <!-- Carte pour la géoloc -->
      <GmapMap
        :center="mapCenter"
        :zoom="zoom"
        style="width: 960px; height: 500px; margin: 2px auto"
      >
        <GmapMarker
        :position="positionMarker"
        :clickable="true"
        :draggable="!onlyReadMode"
        @drag="updateMarkerCoordonate"
        />
      </GmapMap>


      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="abort()" class="my-2 px-4 btn">Fermer</v-btn>
        <v-btn
          v-if="!onlyReadMode"
          outlined
          color="primary"
          class="mr-4 my-2 px-4 btn"
          @click="confirm"
          :disabled="!canSave"
          >Valider</v-btn
        >
      </v-card-actions>
    </v-card>


    <!-- Popup d'erreur de géocodage  -->
    <v-dialog v-model="showError" max-width="1000px" persistent>
      <div>
        <v-alert
          prominent
          type="error"
        >
          <v-row align="center">
            <v-col class="grow">
              Erreur géocodage : l'adresse n'a pas été trouvée. Veuillez la vérifier.
            </v-col>
            <v-col class="shrink">
              <v-btn text @click="closeDialogError()" class="my-2 px-4 btn">Fermer</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>
    </v-dialog>   

  </v-dialog>
</template>



<script>

import GeoApiGouvService from "@/service/geo_api_gouv_service.js";


export default {
  name:"DialogMap",
  components: {},
  
  mixins: [ ],
  
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    onlyReadMode: {
      type: Boolean,
      default: false,
    },
    /** l'adresse */
    addressProp: {
      type: String,
      default: null,
    },
    /** le code postal */
    postalCodeProp: {
      type: String,
      default: null,
    },
    /** la ville */
    cityProp: {
      type: String,
      default: null,
    },
    /** la latitude */
    latitude: {
      type: Number,
      default: null,
    },
    /** la longitude */
    longitude: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      /** Le service de géoloc */
      service: null,

      // L'apparition ou non de la dialog
      show: this.visible,
      // L'apparition ou non de la dialog d'erreur de géocodage
      showError: false,
      
      // Lat et lng affichée
      lat: 43.56,
      lng: 3.85,
      zoom: 15,

      defaultZoom: 6, 
      
      // centre de la carte
      mapCenter: {
        lat: this.lat,
        lng: this.lng,
      },

      // position du marker
      positionMarker: {
        lat: this.lat,
        lng: this.lng,
      },

      defaultPosition: {
        lat: 47.00,
        lng: 2.40,
      },

      // Chargement du géocodage
      loadingGeocodage: false,

      // la couleur  du texte
      colorText: "secondary", 

      // le score de géoloc
      score: null,

    };
  },


  methods: {
    /** Annulation et fermeture de la dialog */
    abort() {
      this.close();
    },

    /** Fermeture de la popup d'erreur */
    closeDialogError() {
      this.showError = false;
    },

    /** L'action est confirmée */
    confirm() {
      this.$emit("confirmed", {
        newLatitude: Number(this.lat),
        newLongitude: Number(this.lng),
      });
      this.close();
    },

    /** Fermeture de la dialog */
    close() {
      this.show = false;
      this.$emit("update:visible", false);
    },

    // Initialisation des datas de carte
    init() {
      this.colorText = "1E1D1D";
      this.updateCoordonates(this.latitude, this.longitude);

      if (this.latitude != 0 && this.longitude != 0) {
        this.updateMapCenter(this.latitude, this.longitude);
        this.updateMarker(this.latitude, this.longitude);
      } else {
        this.updateMapCenter(this.defaultPosition.lat, this.defaultPosition.lng);
        this.positionMarker = null;
        this.zoom = this.defaultZoom;
      }
    },

    /** Demande de géolocalisation de l'adresse */
    async geolocAddress() {
      try {
        this.loadingGeocodage = true;
        // Appel au srvice de géocoding
        let geocode = await this.service.searchByAddressAndPostalCode(this.addressProp, this.postalCodeProp);

        if (geocode) {
          let lt = geocode.latitude;
          let lg = geocode.longitude;
          this.zoom = 15;

          this.colorText = "1E1D1D";
          this.updateCoordonates(lt, lg);
          this.updateMapCenter(lt, lg);
          this.updateMarker(lt, lg);
          this.refreshScore(geocode.score);

        } else {
          this.showError = true;
        }

      } catch (error) {
        console.error(error);
      } finally {
        this.loadingGeocodage = false;
      }
    },

    /** Mise à jour des coordonnées affichées */
    updateCoordonates(lat, lng) {
      this.lat = lat;
      this.lng = lng;
    },
    /** Mise à jour des coordonnées du centre de la carte */
    updateMapCenter(lat, lng) {
      this.mapCenter = {
        lat: lat,
        lng: lng,
      };
    },
    // Mise à jour des coordonnées du marker
    updateMarker(lat, lng) {
      this.positionMarker = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };
    },
    // Mise à jour du score affiché
    refreshScore(score) {
      this.score = score;
    },

    /** Mise à jour des coordonnées en temps réel de déplacement */
    updateMarkerCoordonate(location) {
      let lt = location.latLng.lat().toFixed(6);
      let lg = location.latLng.lng().toFixed(6);

      this.colorText = "E20080";
      this.updateCoordonates(lt, lg);
      this.updateMarker(lt, lg);
      this.refreshScore("manuel");
    },
  },

  computed: {
    canSave() {

      if (this.latitude != this.lat) {
        return true;
      }

      if (this.longitude != this.lng) {
        return true;
      }

      return false;
    },

    /** adress complete */
    fullAddress() {
      let address = [];
      address.push(this.addressProp);
      address.push(this.postalCodeProp);
      address.push(this.cityProp);

      let addressFull = address.join(', ');
      return addressFull;
    },

  },

  watch: {
    visible(value) {
      this.show = value;
    },

    show(value) {
      if (!value) {
        this.lat = null;
        this.lng = null;
        this.$emit("update:visible", false);
      } else {
        this.init();
      }
    },
  },

  mounted() {
    // Instanciation du service
    this.service = new GeoApiGouvService();
  },
};
</script>