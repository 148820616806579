import axios from "axios";


const _SEARCH = "/search";

export default class GeoApiGouv {

  constructor(
    host = process.env.VUE_APP_GEO_API_GOUV,
    timeout = 10000
  ) {
    this.http = axios.create({
      timeout,
    });
    this.host = host;
  }

  /** Recherche de géoloc par adresse et code postal */
  async searchByAddressAndPostalCode(address, postalCode) {
    let url = this.host + _SEARCH;
    

    let param = {
      "q": address,
      "postcode": postalCode
    }

    return this.http.get(url, { params: param,})
  }

}

